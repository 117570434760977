<template>
  <div>
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="mb-8">
      <import-excel :onSuccess="loadDataInTable"/>
    </div>
    <vx-card v-if="tableData.length && header.length">
      <vs-table stripe pagination :max-items="20" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="col" v-for="(col, indexcol) in data[indextr]" :key="indexcol + col">
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="importData" class="mr-6 mt-5">Importar</vs-button>
    </vx-card>
  </div>
</template>

<script>
import ImportExcel from '@/components/excel/ImportExcel.vue'

import { db, FieldValue } from '@/firebase/firebaseConfig'

import ubigeo from '../../data/ubigeo/ubigeo.json'
import departments from '../../data/ubigeo/departments.json'
import provinces from '../../data/ubigeo/province.json'
import districts from '../../data/ubigeo/district.json'

export default {
  name: 'ImportContacts',
  components: {
    ImportExcel
  },
  data () {
    return {
      tableData: [],
      header: [],
      sheetName: '',
      initProgress: false,
      ubigeo: ubigeo,
      departments,
      provinces,
      districts,
      providers: []
    }
  },
  async mounted () {
    try {
      this.initProgress = true
      await this.getProviders()
    } catch (e) {
      console.log(e)
    } finally {
      this.initProgress = false
    }
  },
  methods: {
    loadDataInTable ({ results, header, meta }) {
      this.header = header
      this.tableData = results
      this.sheetName = meta.sheetName
    },
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async importData () {
      try {
        this.initProgress = true
        // Write products
        let batch = db.batch()
        let count = 0
        const batchs = []
        batchs.push(batch)
        let flag = 0
        for (let data of this.tableData) {
          count++
          let obj = {
            provider: this.providers.find((c) => c.mysqlId === data.idReferencia),
            email: data.correoElectronico,
            kindOfDeal: data.tipoTrato,
            lastName: data.apePat,
            name: data.nombres,
            phone: data.movil,
            state: true,
            typeOfContact: data.tipoContacto,
            createdAt: FieldValue.serverTimestamp()
          }
          if (obj.provider && obj.email) {
            const objRef = db.collection('providers').doc(obj.provider.id).collection('contacts').doc()
            if (count < 249) {
              batchs[flag].set(objRef, {
                ...obj
              })
            } else {
              flag++
              count = 0
              batchs.push(db.batch())
            }
          }
        }

        for await (let b of batchs) {
          await b.commit()
        }

        this.$vs.notify({
          color: 'success',
          title: 'Importacion',
          text: 'Importación realizada correctamente.'
        })
        this.initProgress = false
      } catch (e) {
        this.initProgress = false
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Get providers
     */
    async getProviders () {
      const querySnap = await db.collection('providers').orderBy('createdAt', 'desc').get()
      querySnap.forEach((doc) => {
        let client = {
          id: doc.id,
          businessName: doc.data().businessName,
          document: doc.data().document,
          typeDocument: doc.data().typeDocument,
          mysqlId: doc.data().mysqlId
        }
        this.providers.push(client)
      })
    }
  }
}
</script>
